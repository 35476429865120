export const API_URL = 'https://api.eigengeschäfte.de';
export const mail = 'info@eigengeschäfte.de';
export const STRIPE_PUBLIC_KEY =
    'pk_test_51IZjTzBFkmFudWp4sW7SdMewIH5Wj4pIT4l4irhFJVVN4L3DIX86Mytm9QuMCnkjxz3jSAge4jHQiFyzaIEdLwCN00fMZCsQD2';

export const terminNamen = {
    hv_2021: 'Hauptversammlung',
    bilanz_2021: 'Pressekonferenz der Bilanzveröffentlichung',
    q1_2021: 'Veröffentlichung der Quartalszahlen des ersten Quartals',
    q2_2021: 'Veröffentlichung der Quartalszahlen des zweiten Quartals',
    q3_2021: 'Veröffentlichung der Quartalszahlen des dritten Quartals',
    q4_2021: 'Veröffentlichung der Quartalszahlen des vierten Quartals',
};
