import { Helmet } from 'react-helmet';
import Meldung from '../components-new/Meldung';
import SumDetails from '../components-new/SumDetails';

const Startseite = ({ stats }) => {
    const meldungen = stats?.newest || [];

    const sum = meldungen
        .map((e) => e.aggregiertes_volumen)
        .reduce((a, b) => a + b, 0);
    const kauf_summe = meldungen
        .filter((e) => e.art_des_geschaefts === 'Kauf')
        .map((e) => e.aggregiertes_volumen)
        .reduce((a, b) => a + b, 0);
    const verkauf_summe = meldungen
        .filter((e) => e.art_des_geschaefts === 'Verkauf')
        .map((e) => e.aggregiertes_volumen)
        .reduce((a, b) => a + b, 0);
    const sonstiges_summe = meldungen
        .filter((e) => e.art_des_geschaefts === 'Sonstiges')
        .map((e) => e.aggregiertes_volumen)
        .reduce((a, b) => a + b, 0);

    return (
        <>
            <Helmet>
                <meta name="description" content="Startseite" />
                <title>Startseite - Eigengeschäfte</title>
            </Helmet>
            <div className="flex flex-col space-y-4">
                {/* <div className="flex text-sm">
                <div className="py-2 pl-3 pr-2 font-bold text-gray-700 bg-white border-r rounded-l-sm shadow-sm">
                    TAG
                </div>
                <div className="px-2 py-2 font-bold text-gray-700 bg-white shadow-sm">
                    WOCHE
                </div>
                <div className="py-2 pl-2 pr-3 font-bold text-gray-700 bg-white border-l rounded-r-sm shadow-sm">
                    MONAT
                </div>
            </div> */}
                {/* Stats: Käufe, Verkäufe, Sonstiges, Insgesamt */}
                <SumDetails
                    kauf_summe={kauf_summe}
                    verkauf_summe={verkauf_summe}
                    sonstiges_summe={sonstiges_summe}
                    sum={sum}
                />
                {/* Meldungen: Es gab x neue Eigengeschäfte in den letzten 7 Tagen.  */}
                <div className="flex items-baseline p-3 bg-white rounded-sm shadow">
                    <div className="mr-10 text-2xl font-medium">
                        Neue Meldungen
                    </div>
                    <div className="">
                        <span>
                            Dies sind die jüngsten {stats?.newest?.length}{' '}
                            Eigengeschäfte. Es gab insgesamt{' '}
                        </span>
                        <span className="font-bold text-blue-400 underline">
                            {stats?.week_count} neue Eigengeschäfte
                        </span>{' '}
                        <span>in den letzten 7 Tagen</span>
                    </div>
                </div>
                <div className="flex flex-col space-y-4">
                    {stats?.newest?.map((e, pos) => (
                        <div className="" key={pos}>
                            <Meldung {...e} pos={pos} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Startseite;
