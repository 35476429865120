import { useState } from 'react';
import { Helmet } from 'react-helmet';

import { API_URL } from '../config';

const Form = () => {
    const [vorname, setVorname] = useState('');
    const [nachname, setNachname] = useState('');
    const [firma, setFirma] = useState('');
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [response, setResponse] = useState();

    function submitMessage(e) {
        e.preventDefault();
        fetch(`${API_URL}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                message: `${vorname} ${nachname}\n${firma}\n${mail}\n${phone}\n${message}`,
            }),
        })
            .then((res) => res.json())
            .then(setResponse);
    }

    return (
        <div className="flex flex-col items-center px-3 py-8 bg-white rounded-sm shadow">
            <div className="text-3xl font-bold tracking-tight text-center">
                Kontaktieren Sie uns!
            </div>
            <div className="text-2xl font-semibold text-center text-gray-700">
                Falls Sie Fragen an uns haben, melden Sie sich gerne.
            </div>
            <form action="" className="font-bold text-gray-500">
                <div className="flex flex-row flex-wrap mt-6">
                    <div className="w-full mr-2 lg:w-auto lg:mr-6">
                        <div className="mb-1 text-xs select-none">Vorname</div>
                        <input
                            type="text"
                            className="w-full px-3 py-2 border rounded-sm shadow-sm lg:w-auto focus:outline-none"
                            onChange={(e) => setVorname(e.target.value || '')}
                        />
                    </div>
                    <div className="w-full mt-3 lg:mt-0 lg:w-auto">
                        <div className="mb-1 text-xs select-none">Nachname</div>
                        <input
                            type="text"
                            className="w-full px-3 py-2 border rounded-sm shadow-sm lg:w-auto focus:outline-none"
                            onChange={(e) => setNachname(e.target.value || '')}
                        />
                    </div>
                </div>
                <div className="w-full mt-3">
                    <div className="mb-1 text-xs select-none">Firma</div>
                    <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-sm shadow-sm focus:outline-none"
                        onChange={(e) => setFirma(e.target.value || '')}
                    />
                </div>
                <div className="mt-3">
                    <div className="mb-1 text-xs select-none">E-Mail</div>
                    <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-sm shadow-sm focus:outline-none"
                        onChange={(e) => setMail(e.target.value || '')}
                    />
                </div>
                <div className="mt-3">
                    <div className="mb-1 text-xs select-none">
                        Telefonnummer
                    </div>
                    <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-sm shadow-sm focus:outline-none"
                        onChange={(e) => setPhone(e.target.value || '')}
                    />
                </div>
                <div className="mt-3">
                    <div className="mb-1 text-xs select-none">Nachricht</div>
                    <textarea
                        type="text"
                        className="w-full h-32 px-3 py-2 border rounded-sm shadow-sm focus:outline-none"
                        onChange={(e) => setMessage(e.target.value || '')}
                    />
                </div>
                {/* <div className="flex flex-row items-center mt-3 font-normal">
                    <div className="p-1 mr-1">x</div>
                    <div className="p-1">
                        Ja, ich habe die{' '}
                        <a
                            href="https://google.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="font-bold underline"
                        >
                            AGBs
                        </a>{' '}
                        gelesen.
                    </div>
                </div> */}
                <div className="mt-3">
                    <button
                        type="submit"
                        onClick={submitMessage}
                        className={`w-full px-5 py-2 text-center text-white transition bg-gray-700 rounded-sm focus:outline-none hover:bg-gray-800${
                            response ? ' cursor-not-allowed' : ''
                        }`}
                    >
                        {response ? 'Vielen Dank!' : 'Abschicken!'}
                    </button>
                </div>
            </form>
        </div>
    );
};

const Contact = () => {
    return (
        <>
            <Helmet>
                <meta name="description" content="Kontaktieren Sie uns!" />
                <title>Kontakt - Eigengeschäfte</title>
            </Helmet>
            <div className="flex justify-center w-full">
                <Form />
            </div>
        </>
    );
};

export default Contact;
