import { formatm } from '../lib/format';

const SumDetails = ({
    kauf_summe,
    verkauf_summe,
    sonstiges_summe,
    sum,
    stats,
}) => {
    return (
        <div className="flex flex-col justify-start space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
            <div className="p-3 bg-white border-l-2 border-green-600 rounded-sm shadow lg:w-1/4">
                <div className="text-3xl font-semibold">
                    {`${formatm(kauf_summe || 0)}€`}
                </div>
                <div className="flex items-baseline space-x-1 font-medium">
                    <span>Käufe</span>
                    <span className="text-xs">
                        {stats ? 'in den letzten 7 Tagen' : null}
                    </span>
                </div>
            </div>
            <div className="p-3 bg-white border-l-2 border-red-600 rounded-sm shadow lg:w-1/4">
                <div className="text-3xl font-semibold">{`${formatm(
                    verkauf_summe || 0
                )}€`}</div>
                <div className="flex items-baseline space-x-1 font-medium">
                    <span>Verkäufe</span>
                    <span className="text-xs">
                        {stats ? 'in den letzten 7 Tagen' : null}
                    </span>
                </div>
            </div>
            <div className="p-3 bg-white border-l-2 border-blue-800 rounded-sm shadow lg:w-1/4">
                <div className="text-3xl font-semibold">{`${formatm(
                    sonstiges_summe || 0
                )}€`}</div>
                <div className="flex items-baseline space-x-1 font-medium">
                    <span>Sonstige Geschäfte</span>
                    <span className="text-xs">
                        {stats ? 'in den letzten 7 Tagen' : null}
                    </span>
                </div>
            </div>
            <div className="p-3 bg-white border-l-2 border-yellow-500 rounded-sm shadow lg:w-1/4">
                <div className="text-3xl font-semibold">{`${formatm(
                    sum || 0
                )}€`}</div>
                <div className="flex items-baseline space-x-1 font-medium">
                    <span>Insgesamt</span>
                    <span className="text-xs">
                        {stats ? 'letze 7 Tage' : null}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SumDetails;
