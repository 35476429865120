import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../config';

const Indizes = ({ searchAndSet }) => {
    const [indizes, setIndizes] = useState([]);
    const [show, setShow] = useState([false, false, false]);

    const indizes_name = ['dax', 'mdax', 'sdax'];

    useEffect(() => {
        fetch(`${API_URL}/indizes`)
            .then((response) => response.json())
            .then((res) => {
                setIndizes(res);
            });
    }, []);

    return (
        <div className="px-1">
            {indizes_name.map((index, i) => {
                return (
                    <div key={i} className="flex flex-col">
                        <button
                            type="button"
                            className="flex justify-between py-2 pr-2 text-xl font-bold uppercase focus:outline-none"
                            onClick={() =>
                                setShow((e) => {
                                    const e2 = [...e];
                                    e2[i] = !e2[i];
                                    return e2;
                                })
                            }
                        >
                            <div>{index}</div>
                            <div className="focus:outline-none">
                                <div
                                    className={`transform transition ${
                                        show[i] ? 'rotate-180' : 'rotate-0'
                                    }`}
                                >
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                            </div>
                        </button>
                        <div
                            className={`flex flex-col px-2 space-y-1 text-base${
                                show[i] ? '' : ' hidden'
                            }`}
                            key={i}
                        >
                            {indizes?.[index]?.map((e, j) => {
                                return (
                                    <Link
                                        to="/search"
                                        key={j}
                                        type="button"
                                        className="flex items-baseline justify-between rounded-none appearance-none hover:text-white"
                                        onClick={() => searchAndSet(e.lei)}
                                    >
                                        <div>{e.title}</div>
                                        <div className="">{e.count}</div>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Indizes;
