import { useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { API_URL } from './config';
import './index.css';
import Main from './pages-new/Main';
import { ProvideAuth } from './hooks/useAuth';

Amplify.configure(awsconfig);

async function query(q) {
    let token = 'ANONYMOUS';
    try {
        token = (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (error) {
        console.log('Not logged in, using free plan for search');
    }

    const response = await fetch(`${API_URL}/search?query=${q}`, {
        headers: {
            Authorization: token,
        },
    });
    const data = await response.json();

    if (!data) {
        console.log('EMPTY RESPONSE');
        return [];
    }

    return data;
}

const App = () => {
    const [results, setResults] = useState([]);
    const [meldungen, setMeldungen] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [stats, setStats] = useState();

    useEffect(() => {
        query(searchString).then(setResults);
    }, [searchString]);

    function searchAndSet(q) {
        query(q).then(setMeldungen);
    }

    function updateMeldungen() {
        setMeldungen(results);
    }

    useEffect(() => {
        fetch(`${API_URL}/stats`)
            .then((response) => response.json())
            .then((data) => {
                setStats(data);
            });
    }, []);
    return (
        <ProvideAuth>
            <Main
                searchAndSet={searchAndSet}
                stats={stats}
                results={results}
                meldungen={meldungen}
                updateMeldungen={updateMeldungen}
                onSearch={setSearchString}
            />
        </ProvideAuth>
    );
};

export default App;
