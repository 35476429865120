import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { loadStripe } from '@stripe/stripe-js';
import { API_URL, STRIPE_PUBLIC_KEY } from '../config';
import Loading from './Loading';
import { useAuth } from '../hooks/useAuth';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Checkout = ({ children, yearly, planName }) => {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get('success')) {
            setMessage('Abonnement abgeschlossen');
        }

        if (query.get('canceled')) {
            setMessage('Abonnieren abgebrochen');
        }
    }, []);

    const onClick = async () => {
        setLoading(true);
        const stripe = await stripePromise;

        const body = JSON.stringify({
            yearly,
            planName,
            username: user?.username,
            email: user?.attributes?.email,
        });

        const response = await fetch(`${API_URL}/create-checkout-session`, {
            method: 'POST',
            body,
        });
        const session = await response.json();
        const result = await stripe.redirectToCheckout({
            sessionId: session.sessionId,
        });
        setLoading(false);

        if (result.error) {
            setMessage(JSON.stringify(result.error));
        }
    };

    return (
        <div>
            <Helmet>
                <script src="https://js.stripe.com/v3/" />
            </Helmet>
            <button
                type="button"
                disabled={loading}
                onClick={onClick}
                className="px-5 py-2 text-center text-white transition bg-gray-700 rounded-sm focus:outline-none hover:bg-gray-800"
            >
                {loading ? <Loading /> : <>{message || children}</>}
            </button>
        </div>
    );
};

export default Checkout;
