import { React } from 'react';
import { Link } from 'react-router-dom';

const Info = () => {
    return (
        <div className="flex flex-col space-y-3">
            <h1 className="text-2xl font-semibold">Was sind Eigengeschäfte?</h1>
            <p className="text-gray-800">
                &quot;
                <span className="font-mono tracking-tighter">
                    <span className="text-lg tracking-tighter text-black">
                        Als{' '}
                        <a
                            href="https://de.wikipedia.org/wiki/Eigengesch%C3%A4fte_von_F%C3%BChrungskr%C3%A4ften"
                            target="_blank"
                            rel="noreferrer"
                            className="font-semibold hover:underline hover:text-blue-500"
                        >
                            Eigengeschäfte von Führungskräften
                        </a>{' '}
                        [im Englischen auch Directors&apos; Dealings] werden
                        Wertpapiergeschäfte bezeichnet, die Führungskräfte mit
                        Wertpapieren des eigenen Unternehmens tätigen.
                    </span>{' '}
                    Aufgrund des potenziellen Zusammenhangs mit dem überwiegend
                    verbotenen Insiderhandel werden solche Geschäfte von den
                    Aufsichtsbehörden besonders aufmerksam beobachtet. In der
                    Europäischen Union regelt die seit Mitte 2014 gültige{' '}
                    <a
                        href="https://de.wikipedia.org/wiki/Marktmissbrauchsverordnung"
                        target="_blank"
                        rel="noreferrer"
                        className="font-semibold hover:underline hover:text-blue-500"
                    >
                        Marktmissbrauchsverordnung
                    </a>{' '}
                    <a
                        href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32014R0596"
                        target="_blank"
                        rel="noreferrer"
                        className="font-semibold hover:underline hover:text-blue-500"
                    >
                        [Link zur EU-Verordnung]
                    </a>{' '}
                    in{' '}
                    <a
                        href="https://www.mmvo.de/MMVO-Text/Art-19-Eigengeschaefte-von-Fuehrungskraeften/"
                        target="_blank"
                        rel="noreferrer"
                        className="font-semibold hover:underline hover:text-blue-500"
                    >
                        § 19 MMVO
                    </a>{' '}
                    umfangreiche Meldepflichten diesbezüglich.
                </span>
                &quot; - Wikipedia
            </p>
            <h1 className="text-2xl font-semibold">Über diese Webseite!</h1>
            <p>
                Auf dieser Webseite können Sie nach Personen und Firmen suchen,
                die Eigengeschäfte tätigen. Sie sehen die ersten 20
                Suchergebnisse. Falls Sie mehr oder strukturierte bzw.
                aggregierte Daten benötigen, können Sie uns gerne{' '}
                <Link
                    className="font-semibold text-blue-500 hover:text-blue-700 hover:underline"
                    to="/contact"
                >
                    hier
                </Link>{' '}
                kontaktieren. Außerdem bieten wir strukturierten Zugriff auf
                alle Daten über eine API an. Schreiben Sie dazu eine Mail.
            </p>
            <h2 className="text-2xl font-semibold">
                Ist das nicht Insiderhandel?
            </h2>
            <p>
                Ja ist es, denn umgangsprachlich heißen die Eigengeschäfte auch
                Insidergeschäfte. Insidergeschäfte sind aber per se nicht
                illegal.
            </p>
            <h2 className="text-xl font-semibold">Woher stammen die Daten?</h2>
            <p>
                Die Daten stammen von der Bundesanstalt für
                Finanzdienstleistungsaufsicht.
            </p>
            <h2 className="text-xl font-semibold">
                Wie aktuell sind die Daten?
            </h2>
            <p>
                Die Daten stammen werden innerhalb von einer Stunde
                aktualisiert.
            </p>
            <h2 className="text-xl font-semibold">
                Wie weit gehen die Daten zurück?
            </h2>
            <p>
                Öffentlich sind alle Eigengeschäfte ab März 2020. Falls Sie
                Daten benötigen, die älter als ein Jahr sind, bitte{' '}
                <Link
                    className="font-semibold text-blue-500 hover:text-blue-700 hover:underline"
                    to="/contact"
                >
                    kontaktieren
                </Link>{' '}
                Sie uns. Unsere Daten reichen bis nach 2002 zurück.
            </p>
            <h2 className="text-xl font-semibold">
                Ich habe ein Pro-Abonnement abgeschlossen, bin aber noch nicht
                freigeschaltet.
            </h2>
            <p>
                Falls das Pro-Abonnement nicht automatisch nach der Zahlung
                aktiviert wurde, warten Sie 5 Minuten und loggen sich neu ein.
            </p>
            <h2 className="text-xl font-semibold">
                Bietet ihr auch kostenlose Zugänge für Researchprojekte an?
            </h2>
            <p>
                Für Studierende und Journalisten bieten wir einen kostenlosen
                Zugang an.{' '}
                <Link
                    className="font-semibold text-blue-500 hover:text-blue-700 hover:underline"
                    to="/contact"
                >
                    Kontaktieren Sie uns dazu.
                </Link>
            </p>
        </div>
    );
};

export default Info;
