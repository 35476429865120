import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Plot from 'react-plotly.js';
import Meldung from '../components-new/Meldung';
import TransactionTable from '../components-new/TransactionTable';
import SumDetails from '../components-new/SumDetails';

const piesN = [
    'Art des Geschäfts',
    'Art des Geschäfts (Nur Privatpersonen)',
    'Art des Geschäfts (Nur Unternehmen)',
    'Position (Nur Privatpersonen)',
    'Verteilung Firmen & Privatpersonen',
];

const Pie = ({ id, pie }) => {
    const parentRef = useRef(null);

    const [dims, setDims] = useState([0, 0]);

    useEffect(() => {
        if (parentRef.current) {
            const parentHeight = parentRef.current.offsetHeight;
            const parentWidth = parentRef.current.offsetWidth;
            setDims([parentWidth, parentHeight]);
        }
    }, [parentRef]);

    return (
        <div
            className="flex flex-col items-center justify-center p-5"
            ref={parentRef}
        >
            <div className="text-xl font-semibold text-center ">
                {piesN[id]}
            </div>
            <div>
                <Plot
                    data={[
                        {
                            values: Object.values(pie),
                            labels: Object.keys(pie),
                            type: 'pie',
                            textinfo: 'label+percent',
                            marker: {
                                colors: [
                                    '#065F46',
                                    '#991B1B',
                                    '#1E40AF',
                                    '#9D174D',
                                ],
                            },
                        },
                    ]}
                    config={{
                        displayModeBar: false,
                    }}
                    layout={{
                        // height: height / 4,
                        width: dims[0],
                        showlegend: false,
                        hovermode: false,
                        paper_bgcolor: 'rgba(0,0,0,0)',
                        plot_bgcolor: 'rgba(0,0,0,0)',
                        font: {
                            family:
                                'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                        },
                    }}
                />
            </div>
        </div>
    );
};

const Statistiken = ({ stats, searchAndSet }) => {
    let pies = [];
    if (stats) {
        pies = [
            stats.art_des_geschaefts,
            stats.art_des_geschaefts_pp,
            stats.art_des_geschaefts_unternehmen,
            stats.position_pp,
            {
                Firma: stats.is_company.True,
                Privatperson: stats.is_company.False,
            },
        ];
    }

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Statistiken über Eigengeschäfte"
                />
                <title>Statistiken - Eigengeschäfte</title>
            </Helmet>
            {stats ? (
                <>
                    <SumDetails
                        stats
                        kauf_summe={stats?.kauf_summe}
                        verkauf_summe={stats?.verkauf_summe}
                        sonstiges_summe={stats?.sonstiges_summe}
                        sum={
                            stats?.kauf_summe +
                            stats?.verkauf_summe +
                            stats?.sonstiges_summe
                        }
                    />
                    <div>
                        <div className="m-3 text-2xl font-semibold">
                            Größte Transaktion einer Privatperson
                        </div>
                        <Meldung {...stats.max_priv} show disableSpoiler />
                        <div className="m-3 text-2xl font-semibold">
                            Größte Transaktion eines Unternehmens
                        </div>
                        <Meldung {...stats.max_comp} show disableSpoiler />
                    </div>
                    <div className="mt-3 overflow-x-auto bg-white">
                        <TransactionTable
                            searchAndSet={searchAndSet}
                            most_sold_by_comp={stats.most_sold_by_comp}
                            most_bought_by_comp={stats.most_bought_by_comp}
                            most_sold_by_private={stats.most_sold_by_private}
                            most_bought_by_private={
                                stats.most_bought_by_private
                            }
                        />
                    </div>
                    <div className="flex flex-col justify-center py-5 mt-3 bg-white rounded-sm shadow lg:flex-row lg:flex-wrap">
                        {pies?.map((e, i) => (
                            <Pie id={i} pie={e} key={i} />
                        ))}
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Statistiken;
