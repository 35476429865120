import { React, useState } from 'react';
import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Checkout from '../components-new/Checkout';
import { useAuth } from '../hooks/useAuth';

const plans = [
    {
        name: 'Free',
        text:
            'Für jeden, der einfach mal wissen will welche Chefs was kaufen und verkaufen.',
        priceMonth: 0,
        priceYear: 0,
        featureText: 'Features im Hobbyplan:',
        included: [
            'Finden Sie bis zu 20 Eigengeschäfte zu jeder Suchanfrage',
            'Alle Eigengeschäfte der gesamten Datenbank durchsuchbar',
            'Eigengeschäft im Aktienkurs markieren',
            'Interessante Statistiken',
            'Aktuelle Eigengeschäfte',
            'SDAX, MDAX und DAX als Indizes',
        ],
    },
    {
        name: 'Hobby',
        text:
            'Für Privatpersonen, die sich besonders für Eigengeschäfte interessieren.',
        priceMonth: 3.99,
        priceYear: 29.99,
        featureText: 'Features im Hobby-Plan:',
        included: [
            'Alle Features des Free-Plans',
            'Finden Sie bis zu 50 Eigengeschäfte zu Ihrer Suche',
            'Benutzerdefinierte Live-Benachrichtigungen per E-Mail und Telegram',
        ],
    },

    {
        name: 'Business',
        text: 'Für jeden, der sich beruflich diese Informationen benötigt.',
        priceMonth: 19.99,
        priceYear: 199.99,
        featureText: 'Features im Business-Plan:',
        included: [
            'Alle Features des Hobby-Plans',
            'Benutzerdefinierte Live-Benachrichtigungen per E-Mail, RSS, Telegram und WebHooks',
            'Binden Sie unsere Suche über unsere API auf Ihrer Webseite ein',
        ],
    },
    {
        name: 'Enterprise',
        text: 'Wir stimmen die Features auf Sie ab!',
        priceMonth: -1,
        priceYear: -1,
        featureText: 'Welche Enterprise Features bieten wir',
        included: [
            'Alle Features des Business-Plans',
            [
                'Vollen Zugriff auf den Endpoint ',
                <a
                    key="link-1"
                    target="_blank"
                    rel="noreferrer"
                    className="px-2 py-0.5 font-mono bg-pink-100 hover:bg-pink-200 transition rounded-sm focus:outline-none"
                    href="https://www.elastic.co/guide/en/elasticsearch/reference/current/search-search.html"
                >
                    _search
                </a>,
                ' einer ElasticSearch Instanz.',
            ],
            'Direkter Lesezugriff auf DynamoDB',
            'Weitere Statistiken',
            'Beliebige Indizes',
            'Indexierung auch über AWS CloudSearch',
            'Eigengeschäfte bis ins Jahr 2002',
            'Filter- und Sortierungseinstellungen',
        ],
    },
];

const Plans = () => {
    const [yearly, setYearly] = useState(false);
    const { user } = useAuth();

    return (
        <>
            <Helmet>
                <meta name="description" content="Pläne" />
                <title>Aktuelle Eigengeschäfte - Pläne</title>
            </Helmet>
            <div className="flex flex-col items-center px-3 pt-3 pb-16 bg-white shadow rounded-sm-sm">
                <div className="mt-10 text-5xl font-bold tracking-tight">
                    Pläne
                </div>
                <div className="mt-8 text-2xl">
                    Nutzen Sie unsere API für die Einbindung der Eigengeschäfte
                    auf Ihrer Seite.
                </div>
                <div className="mt-5">
                    <div className="flex flex-row p-1 text-gray-800 bg-gray-200 rounded-sm">
                        <button
                            type="button"
                            className={`px-5 py-1 rounded-sm font-semibold transition duration-300 focus:outline-none ${
                                yearly ? 'bg-gray-200' : ' bg-white'
                            }`}
                            onClick={() => setYearly(false)}
                        >
                            Monatlich zahlen
                        </button>
                        <button
                            type="button"
                            className={`px-5 py-1 rounded-sm font-semibold transition duration-300 focus:outline-none ${
                                yearly ? 'bg-white' : 'bg-gray-200'
                            }`}
                            onClick={() => setYearly(true)}
                        >
                            Jährlich zahlen
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-1 mt-16 lg:grid-cols-4">
                    {plans.map((e, i) => {
                        return (
                            <div
                                key={`main-${i}`}
                                className="flex flex-col p-5 mx-4 mb-4 border rounded-sm shadow lg:mb-0"
                            >
                                <div className="text-xl font-bold text-gray-800">
                                    {e.name}
                                </div>
                                <div className="h-16 mt-3 text-gray-400">
                                    {e.text}
                                </div>
                                <div className="mt-3">
                                    {e.priceYear === -1 ? (
                                        <div>
                                            <span className="text-4xl font-bold">
                                                Individuell
                                            </span>
                                        </div>
                                    ) : (
                                        <>
                                            {yearly ? (
                                                <div>
                                                    <span className="text-4xl font-bold">
                                                        {e.priceYear
                                                            .toFixed(2)
                                                            .replace('.', ',')}
                                                    </span>
                                                    € p.a.
                                                </div>
                                            ) : (
                                                <div>
                                                    <span className="text-4xl font-bold">
                                                        {e.priceMonth
                                                            .toFixed(2)
                                                            .replace('.', ',')}
                                                    </span>
                                                    € mntl.
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="flex flex-row justify-center mt-5">
                                    {e.priceMonth <= 0 ? (
                                        <>
                                            {e.priceMonth === 0 ? (
                                                <Link
                                                    key={`search-${i}`}
                                                    to="/"
                                                    className="px-5 py-2 text-center text-white transition bg-gray-700 rounded-sm focus:outline-none hover:bg-gray-800"
                                                >
                                                    Eigengeschäfte suchen
                                                </Link>
                                            ) : (
                                                <Link
                                                    key={`contact-${i}`}
                                                    to="/contact"
                                                    className="px-5 py-2 text-center text-white transition bg-gray-700 rounded-sm focus:outline-none hover:bg-gray-800"
                                                >
                                                    Kontaktieren Sie uns!
                                                </Link>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {user ? (
                                                <Checkout
                                                    key={`abo-${i}`}
                                                    yearly={yearly}
                                                    planName={e.name}
                                                >
                                                    Abonnieren!
                                                </Checkout>
                                            ) : (
                                                <Link
                                                    key={`login-${i}`}
                                                    to="/login"
                                                    className="px-5 py-2 text-center text-white transition bg-gray-700 rounded-sm focus:outline-none hover:bg-gray-800"
                                                >
                                                    Abonnieren
                                                </Link>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="mt-3 border-t">
                                    <div className="p-1 my-3">
                                        {e.featureText}
                                    </div>
                                    <div className="p-1">
                                        <ul className="p-1 list-inside">
                                            {e.included.map((feature, i2) => (
                                                <li
                                                    key={`li-${i}-${i2}`}
                                                    className="flex flex-row items-baseline py-0.5 space-x-2"
                                                >
                                                    <FontAwesomeIcon
                                                        className="text-green-600"
                                                        icon={faCheck}
                                                    />
                                                    <div className="inline">
                                                        {feature}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Plans;
