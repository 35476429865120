import { React, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faTimes } from '@fortawesome/free-solid-svg-icons';
import { API_URL, terminNamen } from '../config';
import Chart from './Chart';
import Loading from './Loading';
import Termine from './Termine';

function annotateTermine(termine) {
    if (!termine) return [];
    console.log(termine);
    return Object.keys(termine)
        .map((key) => {
            if (!termine[key]) return null;

            return {
                date: `${termine[key].split('.')[2]}-${
                    termine[key].split('.')[1]
                }-${termine[key].split('.')[0]}`,
                text: terminNamen[key],
                color: 'orange',
            };
        })
        .filter((e) => e);
}

const ChartBed = ({
    isin,
    art_des_geschaefts,
    datum_des_geschaefts,
    lei,
    preis,
    children,
    defaultShow,
}) => {
    const [show, setShow] = useState(defaultShow || false);
    const [dataError, setDataError] = useState(false);
    const [chartData, setChartData] = useState(null);

    const [termine, setTermine] = useState(null);

    const lastValue = chartData?.values[chartData?.values.length - 1];
    let rendite = 0;
    if (preis) rendite = lastValue / preis - 1;

    useEffect(() => {
        if (!isin) return;
        fetch(`${API_URL}/termine?query=${isin}`)
            .then((response) => {
                if (response.status !== 404) {
                    return response.json();
                }
                return null;
            })
            .then((data) => {
                if (data) setTermine(data);
            })
            .catch(console.log);
    }, [isin]);

    const annotations = [
        {
            date: `${datum_des_geschaefts.split('.')[2]}-${
                datum_des_geschaefts.split('.')[1]
            }-${datum_des_geschaefts.split('.')[0]}`,
            text: 'Eigengeschäft',
        },
    ];

    useEffect(() => {
        if (show && !chartData) {
            fetch(`${API_URL}/series?query=${isin}`)
                .then((response) => {
                    const json = response.json();
                    if (response.status === 200) return json;
                    if (response.status === 400) setDataError(true);
                    return null;
                })
                .then((data) => {
                    if (data) setChartData(data);
                });
        }
    }, [chartData, show, isin]);

    useEffect(() => {
        setDataError(false);
        setShow(defaultShow || false);
        setChartData(null);
    }, [lei, defaultShow]);

    const parentRef = useRef(null);

    const [dims, setDims] = useState([800, 0]);

    useEffect(() => {
        if (parentRef.current) {
            const parentHeight = parentRef.current.offsetHeight;
            const parentWidth = parentRef.current.offsetWidth;
            setDims([parentWidth, parentHeight]);
        }
    }, [parentRef]);

    if (!isin) return null;

    return (
        <div ref={parentRef}>
            <div className="flex justify-between">
                <div className="flex flex-col space-y-1 lg:space-y-0 lg:space-x-1 lg:flex-row">
                    {children}
                    <button
                        type="button"
                        className="px-2 py-1 font-semibold text-green-600 border-2 border-green-600 rounded focus:outline-none hover:text-green-500 hover:border-green-500"
                    >
                        <FontAwesomeIcon icon={faChartLine} /> Aktienkurs und
                        Termine {show ? 'ausblenden' : 'einblenden'}
                    </button>
                </div>
                {show ? (
                    <button
                        type="button"
                        className="hidden px-2 py-1 rounded-sm focus:outline-none hover:bg-gray-700 hover:text-white lg:block"
                        onClick={() => setShow((e) => !e)}
                    >
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </button>
                ) : (
                    <div />
                )}
            </div>
            {show ? (
                <>
                    {rendite ? (
                        <div className="py-3 text-xl font-bold">
                            <div>
                                {art_des_geschaefts === 'Kauf'
                                    ? 'Aktuelle Rendite des Eigengeschäfts'
                                    : null}
                                {art_des_geschaefts === 'Verkauf'
                                    ? 'Entgangende Rendite durch Verkauf'
                                    : null}
                                {art_des_geschaefts === 'Sonstiges'
                                    ? 'Prozentuale Veränderung der Aktie'
                                    : null}
                            </div>
                            <span
                                className={
                                    rendite >= 0
                                        ? 'text-green-600'
                                        : 'text-red-600'
                                }
                            >
                                {(100 * rendite).toFixed(2)}%
                            </span>
                        </div>
                    ) : null}
                    <div className="py-5">
                        {chartData ? (
                            <div className="flex flex-col items-center justify-center">
                                <div className="text-xl font-semibold">
                                    {chartData?.name}
                                </div>
                                <Chart
                                    width={dims[0]}
                                    values={chartData.values}
                                    index={chartData.index}
                                    annotations={[
                                        ...annotations,
                                        ...annotateTermine(termine),
                                    ]}
                                />
                            </div>
                        ) : (
                            <div>
                                {dataError ? (
                                    <div className="inline px-4 py-2 text-base font-medium tracking-wide text-white bg-red-600 rounded-md">
                                        Aktienkurs nicht verfügbar
                                    </div>
                                ) : (
                                    <div className="flex justify-around text-white bg-green-600 rounded cursor-wait">
                                        <span className="inline-flex rounded-md shadow-sm">
                                            <div className="inline-flex items-center py-2 text-base font-medium leading-6 transition duration-150 ease-in-out rounded-md">
                                                <Loading />
                                            </div>
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <Termine termine={termine} />
                </>
            ) : (
                <div />
            )}
        </div>
    );
};

export default ChartBed;
