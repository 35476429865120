/* import {
    faGoogle,
    faFacebook,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; */
import { useEffect, useRef, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Loading from './Loading';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const emailField = useRef(null);
    const passwordField = useRef(null);

    const { user, signIn, signUp, isLoading, errorMessage } = useAuth();

    useEffect(() => {
        const interval = setInterval(() => {
            if (!username && emailField.current) {
                setUsername(emailField.current.value);
                clearInterval(interval);
            }
            if (!password && passwordField.current) {
                setPassword(passwordField.current.value);
                clearInterval(interval);
            }
        }, 100);
    });

    return user ? (
        <Redirect to="/profile" />
    ) : (
        <form
            className="p-3 bg-white rounded-sm shadow-sm"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="mt-1">
                <label htmlFor="username">
                    <div className="mb-1 text-xs font-bold text-gray-500 select-none">
                        E-Mail
                    </div>
                    <input
                        id="username"
                        ref={emailField}
                        name="username"
                        autoComplete="username"
                        placeholder="peter@example.com"
                        type="text"
                        className="w-full px-3 py-2 border rounded-sm shadow-sm appearance-none focus:outline-none"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </label>
            </div>
            <div className="mt-3">
                <label htmlFor="password">
                    <div className="mb-1 text-xs font-bold text-gray-500 select-none">
                        Passwort
                    </div>
                    <input
                        ref={passwordField}
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="password"
                        placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                        className="w-full px-3 py-2 border rounded-sm shadow-sm appearance-none focus:outline-none"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
            </div>
            <div className="flex flex-row justify-end mt-3">
                <Link
                    to="/resetpassword"
                    className="text-sm font-bold tracking-wide"
                >
                    Ich kann mich nicht einloggen.
                </Link>
            </div>
            <div className="mt-3">
                <button
                    type="submit"
                    className={`w-full px-5 py-2 text-center text-white transition bg-green-700 rounded-sm focus:outline-none hover:bg-green-800${
                        isLoading ? ' cursor-wait' : ''
                    }`}
                    onClick={() => signIn(username, password)}
                >
                    {isLoading ? (
                        <div className="flex justify-center">
                            <Loading />
                        </div>
                    ) : (
                        'anmelden'
                    )}
                </button>
                <button
                    type="button"
                    className={`w-full px-5 py-2 text-center text-white transition bg-blue-400 mt-3 rounded-sm focus:outline-none hover:bg-blue-800${
                        isLoading ? ' cursor-wait' : ''
                    }`}
                    onClick={() => signUp(username, password)}
                >
                    {isLoading ? (
                        <div className="flex justify-center">
                            <Loading />
                        </div>
                    ) : (
                        'registrieren'
                    )}
                </button>
                <div
                    className={`w-full px-5 py-2 text-center text-red-900 font-bold transition border-red-700 border mt-3 rounded-sm ${
                        errorMessage ? ' block' : ' hidden'
                    }`}
                >
                    {errorMessage?.message}
                </div>
            </div>
            {/* <div className="flex items-center mt-3">
                <div className="flex-grow h-px bg-gray-400 rounded" />
                <div className="px-5 py-1 text-sm text-gray-800">
                    oder anmelden mit
                </div>
                <div className="flex-grow h-px bg-gray-400 rounded" />
            </div>
            <div className="flex justify-center mt-3 space-x-8">
                <div className="px-4 py-2 border rounded">
                    <FontAwesomeIcon icon={faGoogle} />
                </div>
                <div className="px-4 py-2 border rounded">
                    <FontAwesomeIcon icon={faTwitter} />
                </div>
                <div className="px-4 py-2 border rounded">
                    <FontAwesomeIcon icon={faFacebook} />
                </div>
            </div> */}
        </form>
    );
};

export default LoginForm;
