import { Redirect, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import NotificationPanel from '../components-new/NotificationPanel';

const colors = {
    admin: ' bg-indigo-500',
    pro: ' bg-green-600',
    'Kein Abonnement': ' bg-blue-400',
};

const Profile = () => {
    const { user, signOut, refreshSession } = useAuth();
    const email = user?.attributes?.email;
    const groups = user?.signInUserSession?.idToken?.payload[
        'cognito:groups'
    ] || ['Kein Abonnement'];

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get('success')) {
            refreshSession();
            console.log('Refreshing Session due to account upgrade');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <meta name="description" content="Informationen" />
                <title>Informationen - Eigengeschäfte</title>
            </Helmet>
            {user ? (
                <div className="flex-col space-y-3 bg-white rounded-sm shadow">
                    <div className="flex flex-col justify-between border-b-2 lg:flex-row">
                        <div className="flex items-center justify-start px-3 py-3 space-x-3">
                            {groups.map((group, i) => (
                                <div
                                    key={i}
                                    className={`select-none text-white px-4 py-3 font-bold uppercase rounded-sm shadow${colors[group]}`}
                                >
                                    {group}
                                </div>
                            ))}
                        </div>
                        <div className="p-3 my-3">
                            Du bist eingeloggt als{' '}
                            <span className="font-semibold">{email}</span>.
                        </div>
                        <div className="flex p-3 space-x-3">
                            <Link
                                className="p-3 border rounded-sm shadow"
                                to="/resetpassword"
                            >
                                Passwort ändern
                            </Link>
                            <button
                                className="p-3 font-bold text-red-800 border border-red-800 rounded-sm shadow"
                                onClick={() => {
                                    signOut();
                                }}
                                type="button"
                            >
                                Logout
                            </button>
                        </div>
                    </div>

                    {groups.includes('Kein Abonnement') ? (
                        <div className="p-3">
                            <div className="flex justify-center">
                                Du hast kein Abonnement!
                            </div>
                        </div>
                    ) : null}
                    <div className="flex p-3">
                        <div>
                            <div className="flex p-3 rounded-sm">
                                <NotificationPanel
                                    username={user.username}
                                    pro={groups.includes('pro')}
                                />
                            </div>
                        </div>
                    </div>

                    {groups.includes('admin') ? (
                        <div className="flex flex-col p-3 space-y-3">
                            <button
                                className="flex p-3 border shadow-sm focus:outline-none"
                                onClick={refreshSession}
                                type="button"
                            >
                                Refresh Session
                            </button>
                            <pre className="p-3 overflow-x-scroll font-sans bg-gray-100 text-mono">
                                {JSON.stringify(user, null, 2)}
                            </pre>
                        </div>
                    ) : null}
                </div>
            ) : (
                <Redirect to="/login" />
            )}
        </>
    );
};

export default Profile;
