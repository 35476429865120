import { React } from 'react';
import Plot from 'react-plotly.js';

const Chart = ({ annotations, index, values, width }) => {
    const shapes = [];
    const annos = [];

    annotations?.forEach((annotation) => {
        const date = annotation?.date;
        const color = annotation?.color || 'blue';

        shapes.push({
            type: 'line',
            x0: date,
            x1: date,
            y0: 0,
            y1: Math.max(...values),
            line: {
                color,
                width: 2,
            },
        });
        annos.push({
            x: date,
            y: Math.max(...values) / 2,
            xref: 'x',
            textangle: '-90',
            yref: 'y',
            text: annotation.text,
            showarrow: false,
        });
    });

    /*     const shapes = Object.keys(termine || {})
        .filter((e) => termine[e])
        .map((key) => {
            const datum = termine[key];

            const date = `${datum.split('.')[2]}-${datum.split('.')[1]}-${
                datum.split('.')[0]
            }`;
            return {
                type: 'line',
                x0: date,
                x1: date,
                y0: 0,
                y1: Math.max(...values),
                line: {
                    color: 'blue',
                    width: 2,
                },
            };
        });
    const annotations = Object.keys(termine || {})
        .filter((e) => termine[e])
        .map((key) => {
            const datum = termine[key];

            const date = `${datum.split('.')[2]}-${datum.split('.')[1]}-${
                datum.split('.')[0]
            }`;
            return {
                x: date,
                y: Math.max(...values) / 2,
                xref: 'x',
                textangle: '-90',
                yref: 'y',
                text: terminNamen[key],
            };
        }); */

    /* const rect = {
        type: 'rect',
        x0: left,
        y0: txPrice * 0.9,
        x1: right,
        y1: txPrice * 1.1, // Math.max(...values),
        line: {
            color: 'orange',
            width: 2,
        },
    }; */

    return (
        <Plot
            data={[
                {
                    x: index,
                    y: values,
                    type: 'scatter',
                    mode: 'lines',
                    marker: { color: '#1F2937' },
                },
            ]}
            layout={{
                annotations: annos,
                width,
                shapes,
                showlegend: false,
                xaxis: { fixedrange: true },
                yaxis: { fixedrange: true },
                font: {
                    family:
                        'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                },
                margin: {
                    t: 20,
                    b: 30,
                    l: 20,
                    r: 20,
                },
                hovermode: true,
            }}
            config={{
                displayModeBar: false,
            }}
        />
    );
};

export default Chart;
