import { useRouteMatch, Route } from 'react-router-dom';

import Contact from './Contact';
import Startseite from './Startseite';
import Layout from '../components-new/Layout';
import Suche from './Suche';
import Login from './Login';
import Profile from './Profile';
import Plans from './Plans';
import Informationen from './Informationen';
import ForgotPassword from './ForgotPassword';
import Statistiken from './Statistiken';
import Impressum from './Impressum';
import Indizes from './Indizes';
import Company from './Company';

const Main = ({
    stats,
    results,
    onSearch,
    meldungen,
    updateMeldungen,
    searchAndSet,
}) => {
    const isOnInfo = useRouteMatch('/info');
    const isOnStats = useRouteMatch('/stats');
    const isOnLogin = useRouteMatch('/login');
    const isOnSuche = useRouteMatch('/search');
    const isOnContact = useRouteMatch('/contact');
    const isOnIndizes = useRouteMatch('/indizes');
    const isOnImpressum = useRouteMatch('/impress');
    const isOnProfile = useRouteMatch('/profile');
    const isOnPlans = useRouteMatch('/plans');

    const isOnForgotPassword = useRouteMatch('/resetpassword');
    const isOnCompany = useRouteMatch('/company/:lei');

    const noRoute = ![
        isOnInfo,
        isOnStats,
        isOnContact,
        isOnIndizes,
        isOnImpressum,
        isOnSuche,
        isOnLogin,
        isOnProfile,
        isOnPlans,
        isOnForgotPassword,
        isOnCompany,
    ].reduce((a, b) => a || b);

    return (
        <Layout
            searchAndSet={searchAndSet}
            onSearch={onSearch}
            results={results}
            updateMeldungen={updateMeldungen}
        >
            {isOnInfo ? <Informationen /> : null}
            {isOnStats ? (
                <Statistiken stats={stats} searchAndSet={searchAndSet} />
            ) : null}

            {isOnContact ? <Contact /> : null}
            {isOnIndizes ? <Indizes /> : null}
            {isOnPlans ? <Plans /> : null}
            {isOnProfile ? <Profile /> : null}
            {isOnLogin ? <Login /> : null}
            {isOnSuche ? <Suche meldungen={meldungen} /> : null}
            {isOnForgotPassword ? <ForgotPassword /> : null}
            {isOnImpressum ? <Impressum /> : null}
            {noRoute ? (
                <Startseite stats={stats} meldungen={meldungen} />
            ) : null}

            <Route path="/company/:lei">
                <Company />
            </Route>
        </Layout>
    );
};

export default Main;
