import {
    faBars,
    faChevronDown,
    /*     faChevronDown,
     */ faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const SidebarElement = ({ path, setShowMenu, locations, searchAndSet }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const [showChildren, setShowChildren] = useState(false);
    const { icon, children, name, hidden } = locations[path];

    const searchAndSetAsObj = {
        searchAndSet,
    };

    if (hidden) return null;

    const inner = (
        <div className="flex items-baseline justify-between">
            <div className="flex flex-row justify-center w-8 px-3">
                <div>
                    <FontAwesomeIcon icon={icon} />
                </div>
            </div>
            <div className="flex-grow px-2 text-left">{name}</div>
            {children ? (
                <div className="px-3">
                    <div
                        className={`transform transition ${
                            showChildren ? 'rotate-180' : 'rotate-0'
                        }`}
                    >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                </div>
            ) : (
                <div />
            )}
        </div>
    );

    return (
        <div
            className={`flex flex-col py-2 px-1${
                currentPath === path ? ' text-white' : ''
            }`}
        >
            {children ? (
                <button
                    type="button"
                    className="focus:outline-none hover:text-white"
                    onClick={() => {
                        setShowChildren((e) => !e);
                    }}
                >
                    {inner}
                </button>
            ) : (
                <Link
                    to={path}
                    className="hover:text-white"
                    onClick={() => setShowMenu(false)}
                >
                    {inner}
                </Link>
            )}
            <div className={showChildren ? 'block' : 'hidden'}>
                {children
                    ? React.cloneElement(children, searchAndSetAsObj)
                    : null}
            </div>
        </div>
    );
};

const Sidebar = ({ locations, searchAndSet }) => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <div
            className="flex flex-col items-center w-full p-3 -mt-1 lg:w-2/12 min-w-min lg:min-h-screen"
            style={{ backgroundColor: '#283442', color: '#AEB4BC' }}
        >
            <button
                type="button"
                className="w-full px-3 py-1 text-xl text-left focus:outline-none lg:hidden"
                onClick={() => setShowMenu((e) => !e)}
            >
                <div className="w-8 h-8">
                    <FontAwesomeIcon icon={showMenu ? faTimes : faBars} />
                </div>
            </button>
            <div
                className={`w-full text-xl${
                    showMenu ? '' : ' hidden lg:block'
                }`}
            >
                {Object.keys(locations).map((path, i) => {
                    return (
                        <SidebarElement
                            key={i}
                            path={path}
                            setShowMenu={setShowMenu}
                            locations={locations}
                            searchAndSet={searchAndSet}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Sidebar;
