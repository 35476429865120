import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Auth } from 'aws-amplify';
/* import { SchemaLink } from '@apollo/client/link/schema';
 */ import awsconfig from './aws-exports';
import App from './App';
/* import schema from './graphql/schema.gql';
 */
const graphqlEndpoint = awsconfig.aws_appsync_graphqlEndpoint;
const region = awsconfig.aws_appsync_region;
const authenticationType = awsconfig.aws_appsync_authenticationType;

const auth = {
    type: authenticationType,
    jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const link = ApolloLink.from([
    /*     new SchemaLink({ schema }),
     */
    createAuthLink({
        url: graphqlEndpoint,
        region,
        auth,
    }),
    createHttpLink({ uri: graphqlEndpoint }),
]);
const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});
render(
    <Router>
        <ApolloProvider client={client}>
            <React.StrictMode>
                <Route path="/" component={App} />
            </React.StrictMode>
        </ApolloProvider>
    </Router>,
    document.getElementById('app')
);
