/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:7e2d5934-2db0-4f21-8f89-14d4afca8577",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Vw6asMHMY",
    "aws_user_pools_web_client_id": "2v2dsk4g0h146dnudg6gd4v5a6",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://rljniulucjh2tie6sp3d2va4pq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
};


export default awsmobile;
