import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../components-new/Loading';
import Summary from '../components-new/Summary';
import Termine from '../components-new/Termine';
import { API_URL } from '../config';
import { useAuth } from '../hooks/useAuth';
import { formatm } from '../lib/format';

const trade_color = {
    Kauf: ' text-green-600',
    Verkauf: ' text-red-600',
    Sonstiges: '',
};
const Table = ({ traders }) => {
    let lastName = '';

    return (
        <table>
            <thead>
                <tr className="text-lg text-gray-900">
                    <th className="px-1 py-1 text-left">Name</th>
                    <th className="px-1 py-1 text-left">Art des Geschäfts</th>
                    <th className="px-1 py-1 text-left">Position</th>
                    <th className="px-1 py-1 text-right">Volumen</th>
                </tr>
            </thead>
            <tbody className="divide-y">
                {traders.map((e, i) => {
                    const volFormatted = e.aggregiertes_volumen
                        ? `${formatm(e.aggregiertes_volumen)}€`
                        : null;
                    const name = `${e.vorname} ${e.nachname}`;
                    let showName = name;
                    if (lastName === name) showName = '';
                    lastName = name;

                    return (
                        <tr key={i}>
                            <td className="px-1 py-1 font-semibold">
                                {showName}
                            </td>
                            <td
                                className={`px-1 py-1${
                                    trade_color[e.art_des_geschaefts]
                                }`}
                            >
                                {e.art_des_geschaefts}
                            </td>
                            <td className="px-1 py-1">{e.position}</td>
                            <td className="px-1 py-1 text-right">
                                {volFormatted}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const Company = () => {
    const { lei } = useParams();
    const [companyInfo, setCompanyInfo] = useState();
    const { isAdmin } = useAuth();

    const [termine, setTermine] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!companyInfo) return;
        if (companyInfo?.isins?.length === 0) return;
        console.log('dl termine');
        fetch(`${API_URL}/termine?query=${companyInfo?.isins[0]}`)
            .then((response) => {
                if (response.status !== 404) {
                    return response.json();
                }
                return null;
            })
            .then((data) => {
                if (data) setTermine(data);
            });
    }, [companyInfo]);

    useEffect(() => {
        setLoading(true);
        fetch(`${API_URL}/company/${lei}`)
            .then((response) => response.json())
            .then((data) => {
                setCompanyInfo(data);
                setLoading(false);
            })
            .catch((e) => {
                console.log('Error', e);
                setLoading(false);
            });
    }, [lei]);

    if (companyInfo?.message === 'Not found')
        return <div>Diese LEI gibt es nicht.</div>;

    return (
        <div>
            {loading ? (
                <div className="flex justify-center">
                    <Loading />
                </div>
            ) : (
                <div className="flex flex-col space-y-3">
                    <div className="flex flex-col p-3 bg-white rounded-sm shadow">
                        <div className="text-2xl font-bold">
                            {companyInfo?.name}
                        </div>
                        <div className="text-xl text-gray-700">{lei}</div>
                        <div className="text-normal">{`${companyInfo?.info?.strasse}`}</div>
                        <div className="mb-3 text-normal">
                            {`${companyInfo?.info?.plz} ${
                                companyInfo?.info?.stadt
                            } ${companyInfo?.info?.land || ''}`}
                        </div>
                        <Summary
                            name={
                                companyInfo?.isins
                                    ? companyInfo?.isins[0]
                                    : null
                            }
                        />
                    </div>
                    <div className="flex flex-row p-3 space-x-5 overflow-x-auto bg-white rounded-sm shadow">
                        <div className="">
                            <Table traders={companyInfo?.traders || []} />
                        </div>
                        <div className="w-px h-full bg-gray-200 rounded" />
                        <div className="">
                            <table>
                                <thead>
                                    <tr className="text-lg">
                                        <th className="px-1 py-1 text-left">
                                            Wertpapiere
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {companyInfo?.isins?.map((e) => {
                                        return (
                                            <tr>
                                                <td className="px-1 py-1">
                                                    {e}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {termine ? (
                        <div className="p-3 bg-white rounded-sm shadow">
                            <Termine termine={termine} />
                        </div>
                    ) : null}
                    {isAdmin ? (
                        <pre className="p-3 overflow-x-auto bg-gray-300 rounded-sm shadow">
                            {JSON.stringify(companyInfo, null, 2)}
                        </pre>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default Company;
