import {
    faDigitalTachograph,
    faFileSignature,
    faHome,
    faIdBadge,
    faIdCardAlt,
    faInfo,
    faList,
    faPlusCircle,
    faSearch,
} from '@fortawesome/free-solid-svg-icons';
import Indizes from './pages-new/Indizes';

export const locations = {
    '/': {
        name: 'Startseite',
        icon: faHome,
        description: 'Aktuelle Eigengeschäfte',
    },
    '/login': {
        hidden: true,
        name: 'Login',
        icon: faHome,
        description: 'Login',
    },
    '/search': {
        name: 'Suche',
        icon: faSearch,
        description: 'Suche',
    },
    '/info': {
        name: 'Informationen',
        icon: faInfo,
        description: 'Informationen über Eigengeschäfte',
    },
    '/stats': {
        name: 'Statistiken',
        icon: faDigitalTachograph,
        description: 'Aktuelle Statistiken',
    },
    '/indizes': {
        hidden: false,
        children: <Indizes />,
        name: 'Indizes',
        icon: faList,
        description: 'Die größten deutschen Indizes',
    },
    '/contact': {
        name: 'Kontakt',
        icon: faFileSignature,
        description: 'Kontaktieren Sie uns!',
    },
    '/plans': {
        hidden: true,
        name: 'Pläne',
        description: 'Pläne',
        icon: faPlusCircle,
    },
    '/impress': {
        name: 'Impressum',
        description: 'Impressum',
        icon: faIdCardAlt,
    },
    '/profile': {
        hidden: true,
        name: 'Profil',
        description: 'Profil',
        icon: faIdBadge,
    },
    '/resetpassword': {
        hidden: true,
        name: 'Passwort zurücksetzen',
        description: 'Passwort zurücksetzen',
        icon: null,
    },
    '/company': {
        hidden: true,
        name: 'Firmenansicht',
        description: 'Schau dir eine Firma an',
        icon: null,
    },
};
