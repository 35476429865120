import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Meldung from '../components-new/Meldung';
import SumDetails from '../components-new/SumDetails';
import { useAuth } from '../hooks/useAuth';

const Suche = ({ meldungen }) => {
    const { isPro } = useAuth();

    const sum = meldungen
        .map((e) => e.aggregiertes_volumen)
        .reduce((a, b) => a + b, 0);
    const kauf_summe = meldungen
        .filter((e) => e.art_des_geschaefts === 'Kauf')
        .map((e) => e.aggregiertes_volumen)
        .reduce((a, b) => a + b, 0);
    const verkauf_summe = meldungen
        .filter((e) => e.art_des_geschaefts === 'Verkauf')
        .map((e) => e.aggregiertes_volumen)
        .reduce((a, b) => a + b, 0);
    const sonstiges_summe = meldungen
        .filter((e) => e.art_des_geschaefts === 'Sonstiges')
        .map((e) => e.aggregiertes_volumen)
        .reduce((a, b) => a + b, 0);

    return (
        <>
            <Helmet>
                <meta name="description" content="Suche" />
                <title>Suche - Eigengeschäfte</title>
            </Helmet>
            {/* <div className="flex text-sm">
                <div className="py-2 pl-3 pr-2 font-bold text-gray-700 bg-white border-r rounded-l-sm shadow-sm">
                    TAG
                </div>
                <div className="px-2 py-2 font-bold text-gray-700 bg-white shadow-sm">
                    WOCHE
                </div>
                <div className="py-2 pl-2 pr-3 font-bold text-gray-700 bg-white border-l rounded-r-sm shadow-sm">
                    MONAT
                </div>
            </div> */}
            {/* Stats: Käufe, Verkäufe, Sonstiges, Insgesamt */}
            <SumDetails
                kauf_summe={kauf_summe}
                verkauf_summe={verkauf_summe}
                sonstiges_summe={sonstiges_summe}
                sum={sum}
            />
            {/* Meldungen: Es gab x neue Eigengeschäfte in den letzten 7 Tagen.  */}
            <div className="flex items-baseline p-3 mt-4 bg-white rounded-sm shadow">
                <div className="text-2xl font-medium">
                    {meldungen.length} Suchergebnisse
                </div>
            </div>
            {meldungen?.map((e, pos) => (
                <div className="mt-3" key={pos}>
                    <Meldung {...e} pos={pos} />
                </div>
            ))}
            {meldungen.length === 20 && !isPro ? (
                <div className="relative z-20 flex flex-col items-center justify-center p-3 -mt-6 bg-white rounded-sm shadow">
                    <div className="text-gray-700">
                        Ohne Pro-Plan sind nur die ersten 20 Suchergebnisse
                        einsehbar.
                    </div>
                    <div className="text-2xl font-semibold text-gray-700">
                        <span>
                            Um weitere Eigengeschäfte zu sehen, kontaktieren Sie
                            uns{' '}
                        </span>
                        <Link to="/contact" className="underline">
                            hier
                        </Link>
                        .
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Suche;
