import { Helmet } from 'react-helmet';
import Info from '../components-new/Info';
import Meldung from '../components-new/Meldung';

const exampleMeldung = {
    name: 'Siemens Healthineers AG',
    is_company: false,
    aggregiertes_volumen: 455389.75,
    waehrung: 'EUR',
    plz: '910052',
    strasse: 'Henkestr. 127',
    erlaeuterung: 'Klassischer Kauf',
    art_des_geschaefts: 'Kauf',
    vorname: 'Ralf Peter',
    preis_waehrung: 'EUR',
    ort_des_geschaefts: 'Tradegate',
    land: 'DE',
    lei: '529900QBVWXMWANH7H45',
    titel: 'Prof. Dr.',
    isin: 'DE000SHL1006',
    stadt: 'Erlangen',
    preis: 45.54,
    datum_des_geschaefts: '30.03.2021',
    nachname: 'Thomas',
    art_des_instruments: 'Aktie',
    position: 'Aufsichtsrat',
};
const Informationen = () => {
    return (
        <>
            <Helmet>
                <meta name="description" content="Informationen" />
                <title>Informationen - Eigengeschäfte</title>
            </Helmet>
            <div className="flex flex-col space-y-3">
                <div className="p-3 bg-white rounded-sm shadow">
                    <Info />
                </div>

                <div className="p-3 bg-white rounded-sm shadow">
                    <h2 className="text-xl font-semibold">
                        Wie sieht ein Eigengeschäft aus?
                    </h2>
                    Wir zeigen hier beispielhaft ein Eigengeschäft.
                </div>

                <div className="pt-10">
                    <Meldung {...exampleMeldung} info show disableSpoiler />
                </div>
            </div>
        </>
    );
};
export default Informationen;
