import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../hooks/useAuth';
import Loading from '../components-new/Loading';

const ForgotPassword = () => {
    const {
        user,
        requestPasswordReset,
        forgotPasswordSubmit,
        errorMessage,
        isLoading,
        changePassword,
        message,
    } = useAuth();
    const [username, setUsername] = useState('');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [oldPassword, setOldPassword] = useState();

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Setzen Sie Ihr Passwort zurück!"
                />
                <title>Passwort zurücksetzen - Eigengeschäfte</title>
            </Helmet>
            {user ? (
                <div className="flex justify-center">
                    <div className="flex-col justify-center w-full bg-white divide-x shadow lg:w-1/2">
                        <div className="flex flex-col lg:flex-row">
                            <form
                                className="flex flex-col justify-between w-full p-3 rounded-sm"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <div className="flex justify-center p-3 text-lg font-semibold">
                                    Ich möchte mein Passwort ändern
                                </div>
                                <input
                                    type="password"
                                    name="password"
                                    className="w-full p-3 mr-3 border-t border-l border-r rounded-t-sm rounded-b-none appearance-none focus:outline-none"
                                    placeholder="Altes Password"
                                    onChange={(e) =>
                                        setOldPassword(e.target.value)
                                    }
                                />
                                <input
                                    type="password"
                                    name="password"
                                    className="w-full p-3 mr-3 border rounded-t-none rounded-b-sm appearance-none focus:outline-none"
                                    placeholder="Neues Passwort"
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                />
                                <div className="flex space-x-2">
                                    <button
                                        type="button"
                                        disabled={isLoading}
                                        className="w-full p-3 mt-3 border rounded-sm focus:outline-none"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changePassword(
                                                oldPassword,
                                                newPassword
                                            );
                                        }}
                                    >
                                        {isLoading ? (
                                            <div className="flex items-center justify-center">
                                                <div className="">
                                                    <Loading />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                {message === 'SUCCESS'
                                                    ? 'Passwort wurde geändert'
                                                    : 'Passwort ändern'}
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                        {errorMessage ? (
                            <div className="flex justify-center p-3">
                                <div className="p-3 text-red-700 border border-red-600 rounded-sm shadow">
                                    {errorMessage?.message}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className="flex justify-center">
                    <div className="flex-col justify-center w-full bg-white divide-x shadow lg:w-1/2">
                        <div className="flex flex-col lg:flex-row">
                            <form
                                className="flex flex-col justify-between w-full p-3 rounded-sm"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <div className="flex justify-center p-3 font-semibold">
                                    Ich möchte mein Passwort zurücksetzen
                                </div>
                                <input
                                    type="text"
                                    name="username"
                                    autoComplete="username"
                                    className="w-full p-3 mr-3 border rounded-sm appearance-none focus:outline-none"
                                    placeholder="user@example.com"
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                />
                                <div className="flex space-x-2">
                                    <button
                                        type="button"
                                        disabled={isLoading}
                                        className="w-full p-3 mt-3 border rounded-sm focus:outline-none"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            requestPasswordReset(username);
                                        }}
                                    >
                                        {isLoading ? (
                                            <div className="flex items-center justify-center">
                                                <div className="">
                                                    <Loading />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>Verifikationscode senden</div>
                                        )}
                                    </button>
                                </div>
                            </form>
                            <form
                                className="flex flex-col justify-between w-full p-3 rounded-sm"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <div className="flex justify-center p-3 text-lg font-semibold">
                                    Ich habe einen Code
                                </div>
                                <input
                                    type="text"
                                    className="w-full p-3 mr-3 border-t border-l border-r rounded-t-sm rounded-b-none appearance-none focus:outline-none"
                                    placeholder="Verifizierungscode"
                                    onChange={(e) => setCode(e.target.value)}
                                />
                                <input
                                    type="password"
                                    className="w-full p-3 mr-3 border rounded-t-none rounded-b-sm appearance-none focus:outline-none"
                                    placeholder="Neues Passwort"
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                />
                                <div className="flex space-x-2">
                                    <button
                                        type="button"
                                        disabled={isLoading}
                                        className="w-full p-3 mt-3 border rounded-sm focus:outline-none"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            forgotPasswordSubmit(
                                                username,
                                                code,
                                                newPassword
                                            );
                                        }}
                                    >
                                        {isLoading ? (
                                            <div className="flex items-center justify-center">
                                                <div className="">
                                                    <Loading />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>Passwort zurücksetzen</div>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                        {errorMessage ? (
                            <div className="flex justify-center p-3">
                                <div className="p-3 text-red-700 border border-red-600 rounded-sm shadow">
                                    {errorMessage?.message}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </>
    );
};

export default ForgotPassword;
