import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBuilding,
    faChevronDown,
    faExternalLinkAlt,
    faMapMarked,
    faMoneyBillWave,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { formatm } from '../lib/format';
import Summary from './Summary';
import ChartBed from './ChartBed';
import { useAuth } from '../hooks/useAuth';

const om = {
    14: ' opacity-90',
    15: ' opacity 80',
    16: ' opacity-60',
    17: ' opacity-50',
    18: ' opacity-40',
    19: ' opacity-30',
};

const relation_map = {
    Vorstand: 'bei',
    'in enger Beziehung': 'mit',
    Aufsichtsrat: 'der',
    'Sonstige Führungsperson': 'der',
};
const trade_color = {
    Kauf: ' text-green-600',
    Verkauf: ' text-red-600',
    Sonstiges: '',
};
const border = {
    Kauf: ' border-l-2 border-green-600',
    Verkauf: ' border-l-2 border-red-600',
    Sonstiges: ' border-l-2 border-white',
};
const currency_map = (c) => {
    const curr = {
        EUR: '€',
        USD: '$',
    };
    return c in curr ? curr[c] : ` ${c}`;
};

const Meldung = ({
    art_des_geschaefts,
    is_company,
    vorname,
    nachname,
    position,
    name,
    titel,
    lei,
    isin,
    strasse,
    plz,
    stadt,
    land,
    aggregiertes_volumen,
    preis,
    waehrung,
    datum_des_geschaefts,
    ort_des_geschaefts,
    erlaeuterung,
    art_des_instruments,
    pos,
    show,
    info,
    disableSpoiler,
}) => {
    const relation = relation_map[position];
    const color = trade_color[art_des_geschaefts];
    const currency = currency_map(waehrung);
    const border_color = border[art_des_geschaefts];
    const [fullView, setFullView] = useState(!!show);

    const { isPro } = useAuth();

    let showName = `${titel} ${vorname} ${nachname}`.trim();
    if (showName.length > 35) showName = `${showName.slice(0, 35 - 3)}...`;
    let showNameNoTitle = `${vorname} ${nachname}`.trim();
    if (showNameNoTitle.length > 35)
        showNameNoTitle = `${showNameNoTitle.slice(0, 35 - 3)}...`;

    let showNameComp = name;
    if (showNameComp.length > 35)
        showNameComp = `${showNameComp.slice(0, 35 - 3)}...`;

    const volFormatted = aggregiertes_volumen
        ? `${formatm(aggregiertes_volumen)}${currency}`
        : null;
    /* 
    useEffect(() => {
        if (!show) setFullView(false);
    }, [lei, show]); */

    return (
        <div
            className={`bg-white rounded-sm shadow${border_color}${
                pos in om && !isPro ? om[pos] : ''
            }`}
        >
            <button
                type="button"
                disabled={disableSpoiler || false}
                onClick={() => setFullView(!fullView)}
                className="grid w-full grid-cols-1 px-3 py-3 cursor-default select-text lg:grid-cols-3 focus:outline-none"
            >
                <div className="relative text-2xl font-bold text-left">
                    <FontAwesomeIcon
                        icon={is_company ? faBuilding : faUser}
                        className="mr-2"
                    />
                    {showName}
                    {info ? (
                        <div className="absolute px-2 py-1 text-sm font-bold text-white bg-blue-400 rounded-sm opacity-90 font- -top-10 -left-0">
                            <div>Verkäufer / Käufer</div>
                        </div>
                    ) : null}
                </div>
                <div className="flex items-baseline">
                    <div className="mr-1 text-left">
                        {position} {relation}
                    </div>
                    <div className="relative text-xl text-left">
                        {showNameComp}
                        {info ? (
                            <div className="absolute flex px-2 py-1 text-sm font-bold text-white bg-blue-400 rounded-sm opacity-90 font- -top-14 -left-0">
                                <div className="">
                                    Aktie, die gekauft / verkauft wurde
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="mt-3 lg:mt-0">
                    <div className="grid items-baseline grid-cols-1 lg:grid-cols-3">
                        <div className="flex flex-row justify-between text-right lg:flex-col">
                            <div
                                className={`text-2xl font-semibold relative${color}`}
                            >
                                {volFormatted}
                                {info ? (
                                    <div className="absolute flex px-2 py-1 text-sm font-bold text-white bg-blue-400 rounded-sm font- -top-14 -left-0 opacity-90">
                                        <div className="">
                                            Volumen dieser Transaktion
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            {fullView && volFormatted ? (
                                <div className="text-sm text-gray-700">
                                    Aggregiertes Volumen
                                </div>
                            ) : null}
                        </div>
                        <div className="flex flex-row justify-between mt-2 text-right lg:mt-0 lg:flex-col">
                            <div className="text-xl">
                                {datum_des_geschaefts}
                            </div>
                            {fullView ? (
                                <div className="text-sm text-gray-700">
                                    Datum
                                </div>
                            ) : null}
                        </div>
                        <div className="text-xl lg:pr-3 lg:text-right">
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                className={`cursor-pointer${
                                    fullView
                                        ? ' transform rotate-180 transition'
                                        : ' transform rotate-0 transition'
                                }`}
                            />
                        </div>
                    </div>
                </div>
            </button>
            {fullView ? (
                <div className="grid grid-cols-1 p-3 space-y-5 border-t-2 lg:grid-cols-3">
                    <div className="">
                        <div className="text-lg font-bold">
                            {vorname} {nachname}
                        </div>
                        <Link
                            to={`/company/${lei}`}
                            className="font-semibold text-gray-800 hover:underline hover:text-blue-400"
                        >
                            {position} {relation} {name}
                        </Link>
                        <div className="relative font-thin">
                            {lei}
                            {info ? (
                                <div className="absolute top-0 z-10 flex px-2 py-1 text-sm font-bold text-white bg-blue-400 rounded-sm right-1/3 opacity-90">
                                    <div className="">
                                        Legal Entity Identifier
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="relative font-thin">
                            {isin}
                            {info ? (
                                <div className="absolute top-0 z-10 flex px-2 py-1 text-sm font-bold text-white bg-blue-400 rounded-sm right-1/3 opacity-90">
                                    <div className="">ISIN</div>
                                </div>
                            ) : null}
                        </div>
                        <div>{strasse}</div>
                        <div>
                            {plz} {stadt} {land}
                        </div>
                    </div>
                    <div className="text-sm">
                        <Summary name={isin} />
                    </div>
                    <div className="grid items-baseline justify-between grid-cols-1 pt-3 lg:grid-cols-3">
                        <div className="lg:text-right">
                            {preis ? (
                                <div>
                                    <div className="text-2xl">
                                        {formatm(preis) + currency}
                                    </div>
                                    <div className="text-sm text-gray-700">
                                        Stückpreis
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="lg:text-right">
                            <div>{ort_des_geschaefts}</div>
                            {ort_des_geschaefts ? (
                                <div className="text-sm text-gray-700">
                                    Handelsplatz
                                </div>
                            ) : null}
                        </div>
                        <div className="lg:text-right">
                            <div>{art_des_instruments}</div>
                            {art_des_instruments ? (
                                <div className="text-sm text-gray-700">
                                    Instrument
                                </div>
                            ) : null}
                        </div>
                        <div className={`lg:text-right${color}`}>
                            {art_des_geschaefts !== 'Sonstiges' ? (
                                <div>
                                    <div className="text-xl font-bold">
                                        {art_des_geschaefts}
                                    </div>
                                    <div className="text-sm text-gray-700">
                                        Art des Geschäfts
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {erlaeuterung ? (
                        <div className="py-3 lg:col-span-3">
                            <div className="text-xl font-semibold">
                                Details:
                            </div>
                            <div className="py-3 font-mono">{erlaeuterung}</div>
                        </div>
                    ) : null}
                    <div className="relative lg:col-span-3">
                        <ChartBed
                            isin={isin}
                            lei={lei}
                            preis={preis}
                            art_des_geschaefts={art_des_geschaefts}
                            datum_des_geschaefts={datum_des_geschaefts}
                            defaultShow={info}
                        >
                            <a
                                className="px-2 py-1 font-semibold text-gray-600 border rounded focus:outline-none"
                                href={`https://www.northdata.de/${vorname} ${nachname}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />{' '}
                                {showNameNoTitle} bei Northdata
                            </a>
                            <a
                                className="px-2 py-1 font-semibold text-gray-600 border rounded focus:outline-none"
                                href={`https://www.northdata.de/${name}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />{' '}
                                {showNameComp} bei Northdata
                            </a>
                            <a
                                className="px-2 py-1 font-semibold text-gray-600 border rounded focus:outline-none"
                                href={`https://www.google.com/maps/search/${strasse} ${stadt} ${plz}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faMapMarked} /> Google
                                Maps
                            </a>
                            <a
                                className="px-2 py-1 font-semibold text-gray-600 border rounded focus:outline-none"
                                href={`https://www.ariva.de/search/search.m?searchname=${isin}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon icon={faMoneyBillWave} /> Ariva
                            </a>
                        </ChartBed>
                        {info ? (
                            <div className="absolute flex px-2 py-1 text-sm font-bold text-white bg-blue-400 rounded-sm right-8 bottom-1/4 opacity-90">
                                <div className="">
                                    Aktienkurs mit marktiertem Eigengeschäft in
                                    Orange und Termine in Blau.
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Meldung;
