import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { locations } from '../locations';

const Layout = ({
    children,
    results,
    onSearch,
    updateMeldungen,
    searchAndSet,
}) => {
    const location = useLocation();
    const [pageName, setPageName] = useState();
    const [pageDescription, setPageDescription] = useState();

    useEffect(() => {
        const { name, description } =
            location.pathname in locations
                ? locations[location.pathname]
                : { name: '', description: '' };
        setPageName(name);
        setPageDescription(description);

        // also set nested views
        if (!name) {
            const first_path_layer = `/${location.pathname.split('/')[1]}`;
            console.log(`<${first_path_layer}>`);
            if (first_path_layer in locations) {
                setPageName(locations[first_path_layer].name);
                setPageDescription(locations[first_path_layer].description);
            }
        }
    }, [location]);

    return (
        <div>
            <Navbar
                results={results}
                onSearch={onSearch}
                updateMeldungen={updateMeldungen}
            />
            <div className="flex flex-col mt-1 lg:flex-row">
                <Sidebar locations={locations} searchAndSet={searchAndSet} />
                <div className="flex flex-col w-full lg:w-10/12">
                    <div
                        className="px-6 py-3 lg:py-4"
                        style={{
                            backgroundColor: '#F1F5F8',
                        }}
                    >
                        <div className="">
                            <div className="text-sm">
                                <span className="font-semibold ">
                                    Du bist hier:
                                </span>{' '}
                                <span className="underline">{pageName}</span>
                            </div>
                            <div className="mt-1 text-lg lg:text-3xl">
                                {pageDescription}
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex-grow px-6 pt-4 pb-24"
                        style={{ backgroundColor: '#DEE7EE' }}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
