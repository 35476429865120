import { useState, createContext, useContext } from 'react';
import { Auth } from 'aws-amplify';

import createPersistedState from 'use-persisted-state';

// ----------------------------------------
// AUTH -------
// ----------------------------------------

const AuthContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};

const useUserState = createPersistedState('user');

function useProvideAuth() {
    const [user, setUser] = useUserState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [isLoading, setLoading] = useState(false);

    async function refreshSession() {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = cognitoUser.signInUserSession;
        cognitoUser.refreshSession(
            currentSession.refreshToken,
            (err, session) => {
                setUser((oldUser) => {
                    const newUser = { ...oldUser };
                    newUser.signInUserSession = session;
                    return newUser;
                });
            }
        );
    }

    function changePassword(oldPassword, newPassword) {
        setLoading(true);
        Auth.currentAuthenticatedUser()
            .then((u) => {
                return Auth.changePassword(u, oldPassword, newPassword);
            })
            .then((data) => {
                setLoading(false);
                setErrorMessage(null);
                setMessage(data);
            })
            .catch((err) => {
                setLoading(false);
                setErrorMessage(err);
            });
    }

    async function signIn(username, password) {
        try {
            setLoading(true);
            const signedUser = await Auth.signIn(username, password);
            setLoading(false);
            setUser(signedUser);
            setErrorMessage(null);
        } catch (error) {
            console.log('error signing in', error);
            setLoading(false);
            setErrorMessage(error);
        }
    }

    async function signUp(username, password) {
        try {
            setLoading(true);
            const { signedUser } = await Auth.signUp({
                username,
                password,
            });
            setLoading(false);
            setUser(signedUser);
            setErrorMessage(null);
        } catch (error) {
            console.log('error signing up:', error);
            setLoading(false);
            setErrorMessage(error);
        }
    }

    async function signOut() {
        try {
            setLoading(true);
            const data = await Auth.signOut();
            setMessage(data);
            setLoading(false);
            setUser(false);
            setErrorMessage(null);
        } catch (error) {
            console.log('error signing out: ', error);
            setLoading(false);
            setErrorMessage(error);
        }
    }

    async function verifyCurrentUserAttribute(attr) {
        try {
            setLoading(true);
            const data = await Auth.verifyCurrentUserAttribute(attr);
            setMessage(data);
            setLoading(false);
            setUser(false);
            setErrorMessage(null);
        } catch (error) {
            console.log('error verifying user attr: ', error);
            setLoading(false);
            setErrorMessage(error);
        }
    }

    async function requestPasswordReset(username) {
        try {
            setLoading(true);
            const data = await Auth.forgotPassword(username);
            setMessage(data);
            setLoading(false);
            setUser(false);
            setErrorMessage(null);
        } catch (error) {
            console.log('error resetting password out: ', error);
            setLoading(false);
            setErrorMessage(error);
        }
    }

    async function forgotPasswordSubmit(username, code, new_password) {
        try {
            setLoading(true);
            const data = await Auth.forgotPasswordSubmit(
                username,
                code,
                new_password
            );
            setMessage(data);
            setLoading(false);
            setErrorMessage(null);
        } catch (error) {
            console.log('error entering new password on reset: ', error);
            setLoading(false);
            setErrorMessage(error);
        }
    }

    return {
        user,
        signIn,
        signUp,
        signOut,
        isLoading,
        errorMessage,
        requestPasswordReset,
        refreshSession,
        forgotPasswordSubmit,
        verifyCurrentUserAttribute,
        changePassword,
        message,
        isPro: user?.signInUserSession?.idToken?.payload[
            'cognito:groups'
        ]?.includes('pro'),
        isAdmin: user?.signInUserSession?.idToken?.payload[
            'cognito:groups'
        ]?.includes('admin'),
    };
}
