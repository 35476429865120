import { terminNamen } from '../config';

const Termine = ({ termine }) => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2">
            {Object.keys(termine || {}).map((key, i) => {
                return (
                    <div key={i}>
                        <div className="font-semibold">{terminNamen[key]}</div>
                        <div className="text-left">{termine[key]}</div>
                    </div>
                );
            })}
        </div>
    );
};
export default Termine;
