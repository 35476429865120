import {
    // faBars,
    faBell,
    faExternalLinkAlt,
    faSearch,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const relation_map = {
    Vorstand: 'bei',
    'in enger Beziehung': 'mit',
    Aufsichtsrat: 'der',
    'Sonstige Führungsperson': 'der',
};

const Navbar = ({ results, onSearch, updateMeldungen }) => {
    const history = useHistory();
    const search = useRef({ value: '' });
    const form = useRef();
    const { user, isPro } = useAuth();
    const location = useLocation();

    let resultsUnique = results.map((e) => {
        return {
            name: e.name,
            position: e.position,
            vorname: e.vorname,
            nachname: e.nachname,
            id: `${e.name}-${e.vorname}-${e.nachname}`,
        };
    });
    const seen = new Set();
    resultsUnique = resultsUnique.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
    });

    const notificationCount = 0;

    let suchergebnisCount = results.length;
    if (suchergebnisCount === 20) suchergebnisCount = '20+';
    if (suchergebnisCount >= 50) suchergebnisCount = '50+';
    if (suchergebnisCount === 0) suchergebnisCount = 'Keine';

    return (
        <nav
            className="flex items-center justify-between px-1 py-1 text-gray-700"
            style={{
                boxShadow: '0 3px 4px -1px rgba(0,0,0,0.25)',
            }}
        >
            {/* Left */}
            <Link
                to="/"
                className="flex-shrink hidden w-2/12 px-2 py-3 text-2xl font-bold tracking-tighter text-black cursor-default min-w-min lg:block"
            >
                eigengeschäfte.de
            </Link>
            {/* Middle */}
            <div className="flex flex-row items-center justify-start flex-grow">
                {/* <div className="mr-6 text-3xl">
                    <FontAwesomeIcon icon={faBars} />
                </div> */}
                <form
                    autoComplete="off"
                    ref={form}
                    className="relative flex items-center flex-grow-0 border focus-within:flex-grow"
                    style={{
                        backgroundColor: '#F0F4F7',
                        transitionProperty: 'flex-grow',
                        transitionDuration: '200ms',
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        updateMeldungen();
                        search.current.blur();
                        history.push('/search');
                    }}
                >
                    <button type="submit" className="absolute right-2">
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                    <input
                        type="text"
                        id="searchbox"
                        className="w-full px-3 py-2 rounded searchbox focus:outline-none focus:ring-2 focus:ring-blue-400"
                        ref={search}
                        placeholder="Suche..."
                        onChange={(e) => {
                            e.preventDefault();
                            onSearch(e.target.value);
                        }}
                        style={{
                            backgroundColor: '#F0F4F7',
                            transitionProperty: 'flex-grow',
                            transitionDuration: '200ms',
                        }}
                    />

                    <div
                        id="results"
                        className="absolute z-30 flex flex-col w-full px-3 transition-opacity duration-200 bg-white rounded-b shadow-sm results opacity-95 top-12"
                    >
                        <div className="flex justify-between py-3 font-light text-left cursor-default focus:outline-none">
                            <div>
                                {suchergebnisCount} Suchergebnisse für die
                                Suche: {search?.current.value}
                            </div>
                            <div className="hidden font-mono font-bold tracking-tighter text-gray-500 md:block font-xs">
                                Eingabe zum Suchen
                            </div>
                        </div>
                        {resultsUnique?.map((result, i) => {
                            return (
                                <div
                                    key={i}
                                    className="flex flex-col items-baseline py-1 space-x-1 overflow-y-hidden lg:flex-row focus:outline-none hover:flex"
                                >
                                    <div className="text-xl font-bold">
                                        {result.vorname} {result.nachname}
                                    </div>
                                    <div className="text-sm">
                                        {result.position}{' '}
                                        {relation_map[result.position]}
                                    </div>
                                    <div className="text-base">
                                        {result.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </form>
            </div>
            {/* Right */}
            <div className="flex items-center justify-end w-3/12 px-3 py-2 font-bold min-w-min">
                {isPro ? (
                    <div className="items-center hidden px-3 py-2 mr-3 font-bold text-green-600 transition-colors border border-green-600 rounded lg:flex">
                        Pro
                    </div>
                ) : null}
                {/* Hilfe */}
                <button
                    className="items-center hidden px-3 py-2 mr-8 font-bold text-blue-400 transition-colors border border-blue-400 rounded lg:flex hover:text-blue-800 hover:border-blue-800"
                    type="button"
                    onClick={() => history.push('/info')}
                >
                    <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="mr-1"
                    />
                    <div>Hilfe</div>
                </button>
                {/* Notifcations */}
                <div className="relative mr-10 text-2xl group">
                    <FontAwesomeIcon icon={faBell} />
                    {notificationCount ? (
                        <div className="absolute px-2 py-1 text-xs font-bold text-white transition transform bg-red-600 rounded-full -top-2 left-2 group-hover:scale-125">
                            {notificationCount}
                        </div>
                    ) : null}
                </div>
                {/* Profile */}

                {user ? (
                    <Link
                        to="/profile"
                        className={`flex rounded-sm hover:text-gray-500${
                            location.pathname === '/profile'
                                ? ' text-blue-900'
                                : ''
                        }`}
                    >
                        <FontAwesomeIcon
                            icon={faUser}
                            className="text-2xl lg:mr-4"
                        />
                        <div className="hidden lg:flex">
                            {user?.attributes?.email}
                        </div>
                    </Link>
                ) : (
                    <Link
                        to="/login"
                        className="flex rounded-sm hover:text-gray-500"
                    >
                        <FontAwesomeIcon
                            icon={faUser}
                            className="text-2xl lg:mr-4"
                        />
                        <div className="hidden lg:flex">Einloggen</div>
                    </Link>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
