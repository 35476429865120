import { Helmet } from 'react-helmet';
import LoginForm from '../components-new/LoginForm';

const Login = () => {
    return (
        <>
            <Helmet>
                <meta name="description" content="Login" />
                <title>Login - Eigengeschäfte</title>
            </Helmet>
            <div className="flex justify-center w-full">
                <div className="w-full lg:w-1/2">
                    <LoginForm />
                </div>
            </div>
        </>
    );
};

export default Login;
