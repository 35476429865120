import { Helmet } from 'react-helmet';
import { mail } from '../config';

const Impressum = () => {
    return (
        <>
            <Helmet>
                <meta name="description" content="Impressum" />
                <title>Impressum - Eigengeschäfte</title>
            </Helmet>
            <div className="flex flex-col min-h-screen font-light select-none">
                <div>Tim Dаvid Brаchwitz</div>
                <div>Alsenstrаße 13</div>
                <div>12163 Berlin</div>
                <div>{mail}</div>
            </div>
        </>
    );
};

export default Impressum;
