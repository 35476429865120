import { useState, useEffect } from 'react';
import { API_URL } from '../config';
import Loading from './Loading';

const Summary = ({ name }) => {
    const [summary, setSummary] = useState();
    const [loading, setLoading] = useState(true);
    const [nameState] = useState(name);

    useEffect(() => {
        if (!nameState) {
            return;
        }
        setLoading(true);

        fetch(`${API_URL}/summary?query=${nameState}`)
            .then((response) => {
                if (response) return response.json();
                return null;
            })
            .then((data) => {
                if (data) {
                    setSummary(data);
                    setLoading(false);
                }
            });
    }, [nameState]);

    if (loading)
        return (
            <div className="flex justify-center">
                <Loading />
            </div>
        );

    return summary?.profiltext ? <div>{summary?.profiltext}</div> : <div />;
};

export default Summary;
