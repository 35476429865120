/* eslint-disable jsx-a11y/control-has-associated-label */
import { useQuery, gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import Loading from './Loading';

const exampleKeywords = [
    'Deutsche Bank',
    'Zalando AG',
    'Michael Müller',
    'DE000A2E4K43',
    'Hasso Plattner',
    '*',
    'Adidas',
    'Varta',
    'BASF',
    'RWE',
];
function randomKeyword() {
    return exampleKeywords[Math.floor(Math.random() * exampleKeywords.length)];
}

const LIST_NOTIFICATIONS = gql`
    query listNotifications {
        listNotifications {
            items {
                id
                userid
                type
                keyword
                active
                payload
            }
        }
    }
`;
const ADD_NOTIFICATION = gql`
    mutation MyQuery($input: CreateNotificationInput = {}) {
        createNotification(input: $input) {
            active
            id
            keyword
            payload
            type
            userid
        }
    }
`;

const UPDATE_NOTIFICATION = gql`
    mutation MyQuery2($input: UpdateNotificationInput = { id: "" }) {
        updateNotification(input: $input) {
            active
            id
            keyword
            payload
            type
            userid
        }
    }
`;

const DELETE_NOTIFICATION = gql`
    mutation MyMutation($input: DeleteNotificationInput = { id: "" }) {
        deleteNotification(input: $input) {
            id
        }
    }
`;

const NotificationPanel = ({ username, pro }) => {
    // const [loading, error, data] = ['', ' ', ''];
    const [addNotification, addData] = useMutation(ADD_NOTIFICATION);
    const [deleteNotification, deleteData] = useMutation(DELETE_NOTIFICATION);
    const [updateNotification, updateData] = useMutation(UPDATE_NOTIFICATION);

    const [notifications, setNotifications] = useState([]);
    const { loading, error, data, refetch } = useQuery(LIST_NOTIFICATIONS);

    useEffect(() => {
        if (data) setNotifications(data?.listNotifications?.items);
    }, [loading, error, data]);
    useEffect(() => {
        refetch();
    }, [refetch, username]);

    function onDeleteClick(id) {
        if (deleteData.loading) return;
        deleteNotification({
            variables: {
                input: {
                    id,
                },
            },
        }).then((deletedObject) => {
            const deletedId = deletedObject?.data?.deleteNotification?.id;
            if (deletedId) {
                setNotifications((state) =>
                    state.filter((n) => n.id !== deletedId)
                );
            }
        });
    }

    function onCreateClick(keyword) {
        if (addData.loading) return;
        addNotification({
            variables: {
                input: {
                    keyword,
                    type: 'NOTIFICATION_EMAIL',
                    active: false,
                },
            },
        })
            .then((e) =>
                setNotifications((state) => [
                    ...state,
                    e?.data?.createNotification,
                ])
            )
            .catch(console.log);
    }
    function onSaveClick(active, id, keyword, type, userid) {
        if (updateData.loading) return;
        // console.log('saving', active, id, keyword, type, userid);

        updateNotification({
            variables: {
                input: {
                    active,
                    id,
                    keyword,
                    type,
                    userid,
                },
            },
        }).then((e) => {
            setNotifications((state) =>
                state.map((n) => {
                    if (n.id === id) {
                        return e?.data?.updateNotification;
                    }
                    return n;
                })
            );
        });
    }

    const NotificationControlItem = ({ notification }) => {
        const [active, setActive] = useState(notification.active);
        const [id] = useState(notification.id);
        const [keyword, setKeyword] = useState(notification.keyword);
        const [type, setType] = useState(notification.type);
        const [userid] = useState(notification.userid);

        return (
            <div className="flex flex-col items-baseline space-y-3 lg:space-y-0 lg:space-x-3 lg:flex-row">
                <div className="w-full h-px my-2 bg-gray-400 rounded-sm lg:hidden" />
                <input
                    className="w-full px-3 py-2 border rounded-sm appearance-none lg:w-auto focus:outline-none"
                    type="text"
                    defaultValue={keyword || notification.keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder="Keyword"
                />
                <div className="flex items-baseline space-x-2">
                    <select
                        defaultValue={type}
                        name="notitype"
                        id="type"
                        className="px-3 py-2 border rounded-sm appearance-none focus:outline-none"
                        // placeholder={type}
                        onChange={(e) => {
                            setType(e.target.value);
                        }}
                    >
                        <option value="NOTIFICATION_EMAIL">E-Mail</option>
                        <option value="NOTIFICATION_TELEGRAM">Telegram</option>
                    </select>
                    <input
                        className="px-3 py-2 border rounded-sm"
                        type="checkbox"
                        checked={active}
                        onChange={() => setActive((e) => !e)}
                    />
                    <div>aktiv</div>
                </div>
                <div className="flex space-x-3">
                    <button
                        disabled={updateData.loading}
                        className={`relative px-3 py-2 border border-green-500 rounded-sm focus:outline-none${
                            updateData.loading ? ' cursor-wait' : ''
                        }`}
                        type="submit"
                        onClick={() =>
                            onSaveClick(active, id, keyword, type, userid)
                        }
                    >
                        <div className={updateData.loading ? 'invisible' : ''}>
                            Speichern
                        </div>
                        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                            <div className="">
                                {updateData.loading ? <Loading /> : null}
                            </div>
                        </div>
                    </button>
                    <button
                        disabled={deleteData.loading}
                        className={`relative px-3 py-2 border border-red-500 rounded-sm focus:outline-none${
                            deleteData.loading ? ' cursor-wait' : ''
                        }`}
                        type="submit"
                        onClick={() => onDeleteClick(id)}
                    >
                        <div className={deleteData.loading ? 'invisible' : ''}>
                            Löschen
                        </div>
                        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                            {deleteData.loading ? <Loading /> : null}
                        </div>
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col space-y-3">
            <div className="text-xl">
                Live-Benachrichtigungseinstellungen bearbeiten
            </div>
            <div className="flex flex-col space-y-1">
                {notifications?.map((e, i) => {
                    return <NotificationControlItem notification={e} key={i} />;
                })}
            </div>
            <div className="flex flex-col space-y-3 lg:space-y-0 lg:space-x-3 lg:flex-row">
                <button
                    disabled={addData.loading}
                    className={`relative px-3 py-2 border bg-blue-500 text-white rounded-sm focus:outline-none${
                        addData.loading ? ' cursor-wait' : ''
                    }`}
                    type="button"
                    onClick={() => onCreateClick(randomKeyword())}
                >
                    <div className={addData.loading ? 'invisible' : ''}>
                        Neue Benachrichtigung erstellen
                    </div>
                    <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                        {addData.loading ? <Loading /> : null}
                    </div>
                </button>
                {pro ? (
                    ''
                ) : (
                    <div className="flex justify-center px-3 py-2 border border-red-600 rounded-sm">
                        Benachrichtigungen werden nur an PRO-Nutzer versendet.
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationPanel;
