import { React } from 'react';
import { Link } from 'react-router-dom';
import { formatm } from '../lib/format';

const TransactionTable = ({
    most_sold_by_comp,
    most_bought_by_comp,
    most_sold_by_private,
    most_bought_by_private,
    searchAndSet,
}) => (
    <table className="p-2 border-pink-700 divide-y divide-gray-200 rounded shadow table-fixed">
        <thead className="bg-gray-50">
            <tr className="">
                <th colSpan={2} className="w-1/4">
                    Meistverkaufte Aktien von <div>Unternehmen</div>
                </th>
                <th colSpan={2} className="w-1/4">
                    Meistgekaufte Aktien von <div>Unternehmen</div>
                </th>
                <th colSpan={2} className="w-1/4">
                    Meistverkaufte Aktien von <div>Privatpersonen</div>
                </th>
                <th colSpan={2} className="w-1/4">
                    Meistgekaufte Aktien von <div>Privatpersonen</div>
                </th>
            </tr>
            <tr className="">
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                    Name
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                    Volumen
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                    Name
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                    Volumen
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                    Name
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                    Volumen
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                    Name
                </th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                    Volumen
                </th>
            </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
            {[...Array(10).keys()].map((rowId) => (
                <tr key={rowId}>
                    {[
                        most_sold_by_comp,
                        most_bought_by_comp,
                        most_sold_by_private,
                        most_bought_by_private,
                    ].map((cat, catId) => [
                        <td
                            className="px-3 py-2 w-1/8"
                            key={`${`${rowId}-${catId}`}-1`}
                        >
                            <Link
                                className="hover:underline hover:text-blue-500"
                                to="/search"
                                onClick={() => {
                                    searchAndSet(Object.keys(cat)[rowId]);
                                }}
                            >
                                <div className="font-medium">
                                    {Object.keys(cat)[rowId]}
                                </div>
                            </Link>
                        </td>,
                        <td
                            className={`text-right w-1/8 px-3 py-2 font-bold ${
                                catId === 0 || catId === 2
                                    ? 'text-pink-800'
                                    : 'text-green-700'
                            }`}
                            key={`${`${rowId}-${catId}`}-2`}
                        >
                            ~{formatm(Object.values(cat)[rowId])}
                        </td>,
                    ])}
                </tr>
            ))}
        </tbody>
    </table>
);

export default TransactionTable;
